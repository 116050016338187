<template>
  <div class="container">
    <div class="bgContainer">
      <img class="bgImage" src="@/assets/pageBg/index.jpg" />
      <!-- <div class="bgImageText">

        <h1>福州凝聚空间</h1>
        <p class="bgText">
          福州凝聚空间，简称“凝聚空间”，创立于2020年10月，位于福建省福州市马尾区儒江西路60号中建海峡商务广场B座14层， 是由福州凝聚信息科技有限公司发起成立并运营的线上线下一体化创新创业服务平台。凝聚空间主要服务于 互联网+、电子信息、文化创意、商业模式创新等领域创业团队和初创公司。致力于打造成科技创新创业服务载体 ，一期规划建设场地面积1200多平，舒适的定制化办公环境，整合了独立办公区、开放工作区、路演区和健身、 读书区等个性化功能区。业务涵盖创业孵化，企业服务、产业整合、股权投资等多个领域。
        </p>
      </div> -->

    </div>
    <!-- 板块一：业务中心 -->
    <div class="section1">
      <div class="oneContainer">
      <h1>业务介绍</h1>
      <p class="subheading">用我们的专业和诚信赢得您的信赖，从PC到移动互联网给予您最需要的服务！</p>
      <div class="sectionContainer">
        <div class="row row_top">
          <div class="column" v-for="(item, index) in businessList" :key="index">
            <img class="image" :src="item.img" />
            <p>{{item.title}}</p>
          </div>
        </div>
      </div>
      </div>
    </div>

    <!-- 板块2 -->
    <div class="section2">
      <div class="twoContainer">
      <div class="sectionImageContainer">
        <img class="sectionImage" src="@/assets/image/section2.png" />
      </div>
      </div>
    </div>

    <div class="section3">
      <div class="threeContainer">
        <h1>创造令人愉悦且具有感染力的用户体验</h1>
        <div class="business">
          <template v-for="item in experienceList">
            <div class="experience_block">
              <div class="block_title">
                <img :src="item.img">
                <h4>
                  {{item.title}}
                  <span>{{item.english}}</span>
                </h4>
              </div>
              <p class="block_text">{{item.text}}</p>
            </div>
          </template>
          <!-- <div class="sideBar">
            <div class="sideBarText" @click="click('平台管理成果')">平台管理成果</div>
            <div class="sideBarText" @click="click('产品开发客户案例')">产品开发客户案例</div>
            <div class="sideBarText" @click="click('商务互联网服务')">商务互联网服务</div>
            <div class="sideBarText" @click="click('移动互联网培训中心')">移动互联网培训中心</div>
          </div>
          <div class="rightBar">
            <span class="pText">{{inputText}}</span>
          </div> -->
        </div>
      </div>
    </div>

    <!-- 板块4 -->
    <div class="section4">
     <div class="fourContainer">
      <div class="project_top">
         <h1 style="margin-right:30px;">项目展示</h1>
         <div class="project_block">
          <div class="projectLogoContainer">
            <img class="projectLogo" src="@/assets/logo/wesheng.png" />
          </div>
          <div class="projectText">
            <p>
              维升投票系统的主要功能是发布与分享用户利用该平台模板及配置制作的面向微信软件的投票网站。随着微信用户群体的增加，一些微信附加功能的需求也随之增加。而大部分的用户并不知道如何使用这些功能，只能求助于第三平台。而维升投票平台,恰恰解决了用户不会使用微信投票功能的需求。该投票平台具有操作方便，界面简洁，流程简 单的特点
            </p>
          </div>
         </div>

        <div class="project_block">
          <div class="projectLogoContainer">
            <img class="projectLogo" src="@/assets/logo/dida.png" />
          </div>
          <div class="projectText">
            <p>
              滴答规划是一款提醒事项与时间管理的APP，它会时刻提醒你珍惜自己的时间，可以精确到秒。支持分类管理功能，同时支持「TCPU面板」，「年龄计算器」，「全屏时钟」，「日期计算器」，「二维码生成」等小工具。该APP让您每分每秒都能够看到时间的变化，提醒您珍惜时间。
            </p>
          </div>
        </div>
      </div>

      <div class="project_bottom">
        <div class="project_block">
          <div class="projectLogoContainer">
            <img class="projectLogo" src="@/assets/logo/zanmb.png" />
          </div>
          <div class="projectText">
            <p>
              攒面包是一个可以让用户快速获得活动折扣和商品折扣的平台。用户能够通过我们平台获得外卖活动以及外卖商品等分享链接，用户通过将对应链接分享给其他人。当其他人从分享链接中成功完成订单时，该用户就可以在平台中获得对应比例的佣金。
            </p>
          </div>
        </div>
        
        <div class="project_block">
          <div class="projectLogoContainer">
            <img class="projectLogo" src="@/assets/logo/dandan.png" />
          </div>
          <div class="projectText">
            <p>
              怪蛋大乱战是-款竞技类游戏，玩家可以操作主角蛋蛋和其他玩家一起游戏，通过释放技能和拾取道具，率先到达终点者获得胜利。
            </p>
          </div>
        </div>
      </div>
      </div>
    </div>

    <div class="section6">
      <div class="threeContainer">
        <h1>用简洁优雅的代码创作魔术师般的神奇</h1>
        <div class="business">
          <template v-for="item in technologyList">
            <div class="experience_block">
              <div class="block_title">
                <img :src="item.img">
                <h4>
                  {{item.title}}
                  <span>{{item.english}}</span>
                </h4>
              </div>
              <p class="block_text">{{item.text}}</p>
            </div>
          </template>
        </div>
      </div>
    </div>

    <div class="section5">
      <div class="fiveContainer">
      <h1>联系我们</h1>
      <div class="row">
        <div class="communication">
          <el-form ref="form" :model="form" label-width="80px" :rules="rules">
            <el-form-item label="姓名：">
              <el-input v-model="form.name"></el-input>
            </el-form-item>
            <el-form-item label="邮箱：" prop="email">
              <el-input v-model="form.email"></el-input>
            </el-form-item>
            <el-form-item label="电话：" prop="phone">
              <el-input v-model="form.phone" maxlength="11" show-word-limit></el-input>
            </el-form-item>
            <el-form-item label="留言： ">
              <el-input type="textarea" v-model="form.desc" rows="4" resize="none"></el-input>
            </el-form-item>
            <el-form-item class="submit_btn">
              <el-button type="primary" @click="onSubmit">立即提交</el-button>
              <!-- <el-button>取消</el-button> -->
            </el-form-item>
          </el-form>
        </div>

        <div class="CompanyDetail">
          <p>福州凝聚信息科技有限公司</p>
          <p>电话：0591 - 83972230</p>
          <p>邮箱：gm@fznjkj.com</p>
          <p>网址：www.fznjkj.com</p>
          <p>地址：位于福建省福州市马尾区儒江西路60号中建海峡商务广场B座14层</p>
        </div>
      </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NingHome",
  data() {
    return {
      businessList: [{
        img: require("@/assets/image/image3.jpg"),
        title: "创业孵化"
      },{
        img: require("@/assets/image/image4.jpg"),
        title: "企业服务"
      },{
        img: require("@/assets/image/image1.jpg"),
        title: "技术拓展"
      },{
        img: require("@/assets/image/image2.jpg"),
        title: "商业运营"
      }],
      experienceList: [{
        img: require("@/assets/image/project.png"),
        title: "项目需求分析",
        english: "Project requirement analysis",
        text: "从专业角度分析项目背景，定位目标客户，梳理需求要素，提炼需求核心。为项目整理输出可落地实施的项目计划书。"
      },{
        img: require("@/assets/image/user.png"),
        title: "用户体验研究",
        english: "User experience research",
        text: "团队积累了极其丰富的用户研究方法与经验。能够更高效的洞察问题、了解用户。为项目实施提供有效依据。"
      },{
        img: require("@/assets/image/interaction.png"),
        title: "交互原型设计",
        english: "Interactive prototype design",
        text: "以用户体验为中心，围绕提高产品可用性，通过分析需求，研究用户，输出可落地实现的线框原型及低保真交互模型等。"
      }],
      technologyList: [{
        img: require("@/assets/image/wechat.png"),
        title: "微信 & Wap",
        english: "Wap development",
        text: "针对微信公众号，提供各类微官网、微商城、微营销活动以及各类手机WAP网站、Web App的前端开发和后端功能开发。"
      },{
        img: require("@/assets/image/app.png"),
        title: "IOS & Android",
        english: "App development",
        text: "针对IOS和Android平台，为手机、平板电脑等客户端运行的APP 软件提供专业优质的程序研发和应用市场上架服务。"
      },{
        img: require("@/assets/image/web.png"),
        title: "Web项目开发",
        english: "Web development",
        text: "为客户提供专业的Web研发技术解决方案，包括企业网站、品牌网站、互动网站、电商平台以及基于B/S架构的应用系统等。"
      },{
        img: require("@/assets/image/h5.png"),
        title: "H5前端开发",
        english: "Front end development",
        text: "为客户提供基于Html5/Css3技术的前端开发服务。代码遵循W3C标准，优雅降级兼容各类主流浏览器。"
      },{
        img: require("@/assets/image/php.png"),
        title: "Php项目开发",
        english: "Php development",
        text: "为客户提供Php项目开发服务，简洁优雅构的建每一行代码，确保程序稳定运行。"
      },{
        img: require("@/assets/image/java.png"),
        title: "Java项目开发",
        english: "Java development",
        text: "为客户提供Java项目开发服务，简洁优雅构的建每一行代码，确保程序稳定运行。"
      }],
      inputText: "平台管理成果",
      form: {
        name: "",
        email: "",
        phone: "",
        desc: "",
      },
      rules: {
        email: [
          { type: 'email', message: '请输入正确的邮箱地址', trigger: "blur" }
        ],
        phone: [
          {
            pattern:
              /^(13[0-9]|14[579]|15[0-3,5-9]|16[6]|17[0135678]|18[0-9]|19[89])\d{8}$/,
            message: "请输入正确的手机号",
            trigger: "blur"
          },
        ],
      }
    };
  },
  methods: {
    onSubmit() {

    },

    click(message) {
      this.inputText = message;
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import '../../commonScss/style.css';
.container {
  width: 100%;
  min-width: 1400px;
  /*max-width:1900px;*/
  margin: auto;
  /* width:expression_r(Math.max(1300, Math.min(1900, document.body.offsetWidth*0.99))+"px" ); */
}

h1 {
  margin-top: 0;
  text-align: center;
}
.section1,
.section2,
.section3,
.section4,
.section5 {
  width: 100%;
  height: 800px;
  margin: auto;
}

/* 板块1 */
.section1{
  height: auto;
  background-color: rgb(243, 243, 243);
  padding: 60px 0;
}
.oneContainer{
  width: 1400px;
  margin: auto;
}
.subheading {
  text-align: center;
  margin-bottom: 50px;
}
.sectionContainer {
  /*display: flex;
  flex-direction: column;
  align-content: center;*/
  width: 100%;
  /*height: 300px;*/
}
.image {
  width: 320px;
  height: 210px;
}
/* 板块1 */

/* 板块2 */
.section2 {
  height: auto;
}
.twoContainer{
  width: 1400px;
  padding: 50px 0;
  margin: auto;
}
.sectionImageContainer{
  width: 700px;
  margin: auto;
}
.sectionImage{
  width: 100%;
  height: 100%;
}
/* 板块2 */

/* 板块3 */
.section3 {
  background-color: rgb(243, 243, 243);
  height: auto;
  padding: 60px 0;
}
.threeContainer{
  width: 1400px;
  margin: auto;
}
.threeContainer h1 {
  margin-top: 0;
  margin-bottom: 40px;
}
.business {
  display: flex;
  /*justify-content: center;*/
  flex-wrap: wrap;
}
.experience_block {
  width: 33.33%;
  padding: 30px 50px;
  box-sizing: border-box;
}
.experience_block .block_title {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.experience_block .block_title img {
  width: 80px;
}
.experience_block .block_title h4 {
  margin: 0;
  font-weight: 200;
  font-size: 24px;
  color: #121212;
  margin-left: 20px;
}
.experience_block .block_title h4 span {
  display: block;
  line-height: 25px;
  color: #4e4e4e;
  font-size: 16px;
}
.experience_block p {
  margin: 0;
  line-height: 25px;
  color: #666;
  text-indent: 2em;
}
.sideBar {
  min-width: 20%;
  margin-right: 30px;
}
.sideBarText {
  text-align: center;
  font-size: 25px;
  width: 300px;
  height: 100px;
  line-height: 100px;
  border: 1px solid #000;
  cursor: pointer;
}
.rightBar {
  min-width: 50%;
  margin-left: 30px;
  height: 400px;
  float: left;
  border-color: #000;
  border-style: solid;
  border-width: 1px 3px 3px 1px;
  border-radius: 6px;
}
.pText {
  text-align: left;
  font-size: 20px;
  margin: 12px;
}
/* 板块3 */

/* 板块4 */
.section4 {
  height: auto;
}
.fourContainer{
  width: 1400px;
  padding: 60px 0;
  margin: auto;
}
.project_top,
.project_bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.project_top .project_block {
  width: 44%;
}
.project_bottom .project_block {
  width: 50%;
}
.project_block {
  display: flex;
  align-items: center;
}
.projectLogoContainer {
  width: 150px;
  /*height: 120px;*/
  margin-right: 40px;
}
.projectLogo {
  width: 100%;
  height: 100%;
}
.projectText {
  font-size: 20px;
  display: flex;
  align-items: center;
}
.project_top .projectText {
  width: calc(100% - 240px);
}
.project_bottom {
  margin-top: 50px;
}
.project_bottom .projectText {
  width: calc(100% - 280px);
}
.projectText p {
  text-align: justify;
  text-indent: 2em;
  margin: 0;
}
/* 板块4 */

/* 板块5 */
.section5 {
  background-color: white;
  height: auto;
}
.fiveContainer {
  width: 1400px;
  margin: auto;
  padding: 60px;
}
.communication {
  width: 36%;
  /*margin-left: 30px;*/
  margin-top: 30px;
}
.communication .submit_btn {
  margin-bottom: 0;
}
.CompanyDetail{
  margin-left: 80px;
}
/* 板块5 */

/* 板块6 */
.section6 {
  width: 100%;
  background-color: rgb(243, 243, 243);
  padding: 60px 0;
}
/* 板块6 */

.row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.row1 {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.column {
  display: flex;
  flex-direction: column;
}
.column p {
  margin: 0;
  padding: 8px 0;
  text-align: center;
  background: #999;
  color: #fff;
}
.column:nth-child(n + 2) {
  margin-left: 30px;
}
</style>
